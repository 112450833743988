<template>
  <Div :darkMode="darkMode">
    <Layout class="layout">
      <Header
        :style="{
          position: 'fixed',
          width: '100%',
          top: 0,
          background: '#E7004C',
          padding: '0',
        }"
      >
        <a-row>
          <a-col
            :lg="!topMenu ? 12 : 11"
            :md="14"
            :sm="collapsed ? 6 : 12"
            :xs="collapsed ? 6 : 12"
            class="align-center-v navbar-brand"
          >
            <Button
              class='btn-collapsed'
              v-if="collapsed"
              @click="toggleCollapsed"
              style="background: none;border: none;position: relative;left: 5rem;height: 4rem;"
            >
              <img :src="require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)" alt="menu" />
            </Button>
            <Button
              class='btn-collapsed'
              v-if="!collapsed"
              @click="toggleCollapsed"
              style="background: none;border: none;position: relative;left: 18rem;height: 4rem;"
            >
              <img :src="require(`../static/img/icon/${collapsed ? 'right.svg' : 'left.svg'}`)" alt="menu" />
            </Button>
            <div style="width: 80%">
              <p class="title-bold-normal break-text" 
                v-if="width>480" 
                :style='{
                  position: "relative",
                  left: collapsed ? "6rem" : "19rem",
                  height: "3rem",
                  "font-size": "25px",
                  color: "#ffff"}'>
                {{ titleHeader }}
              </p>
            </div>
          </a-col>

          <a-col :lg="8" :md="4" :sm="12" :xs="14" v-if='collapsed || width>480'>
            <a-row style="margin-right: 0.5rem;" >
              <div style="display: flex; justify-content: flex-end; width: 100%; gap: 15px">
                <div class='header--notification-icon'>
                  <Notification
                    :notifications='this.notifications'
                    :delete-notification='deleteNotification'
                  />
                  <span class="vertical-line"></span>
                </div>
              </div>
            </a-row>
          </a-col>
          <a-col :lg="3" :md="5" :sm="0" :xs="0">
            <a-row>
              <a-col :lg="24" :md="24" :sm="24" :xs="24" style="height: 13px;">
                <p style="color: #FFFFFF;" class="title-bold-normal break-text">
                  {{ dataDOM.userName }}
                </p>
              </a-col>
              <a-col :lg="24" :md="24" :sm="24" :xs="24">
                <p style="color: #FFFFFF;" class="title-normal break-text">
                  <!--{{ dataDOM.rolesUser }}-->
                </p>
              </a-col>
            </a-row>
          </a-col>
          <a-col :lg="1" :md="1" :sm="0" :xs="0">
            <AuthInfo />
          </a-col>
          <a-col :style="{ position: 'static' }" :md="0" :sm="18" :xs="12">
            <div class="mobile-action">
              <AuthInfo />
            </div>
          </a-col>
        </a-row>
      </Header>
      <div class="header-more">
        <a-row>
          <a-col :md="0" :sm="24" :xs="24">
            <div class="small-screen-headerRight">
              <SmallScreenAuthInfo :hide="hide" :darkMode="darkMode">
                <AuthInfo :rtl="rtl" />
              </SmallScreenAuthInfo>
            </div>
          </a-col>
        </a-row>
      </div>
      <Layout>
        <template v-if="!topMenu">
          <Sider
            :width="280"
            :style="{
              margin: '0px 0 0 0',
              padding: '0',
              overflowY: 'auto',
              height: '100vh',
              position: 'fixed',
              [!rtl ? 'left' : 'right']: 0,
              zIndex: 999,
            }"
            :collapsed="collapsed"
            :theme="!darkMode ? 'light' : 'dark'"

          >
            <perfect-scrollbar
              :options="{
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: true,
              }"
            >
              <AsideItems
                :toggleCollapsed="toggleCollapsedMobile"
                :topMenu="topMenu"
                :rtl="rtl"
                :darkMode="darkMode"
                :events="onEventChange"
                :hideImage="collapsed"
              />
            </perfect-scrollbar>
          </Sider>
        </template>
        <Layout class="atbd-main-layout">
          <Content>
            <Suspense>
              <template #default>
                <router-view></router-view>
              </template>
              <template #fallback>
                <div class="spin">
                  <a-spin />
                </div>
              </template>
            </Suspense>


          </Content>
        </Layout>
      </Layout>
    </Layout>
  </Div>
</template>
<script>
import { Layout } from 'ant-design-vue';
import { Div, SmallScreenAuthInfo } from './style';
import AuthInfo from '../components/utilities/auth-info/info';
import AsideItems from './Aside';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';
import { getItem } from '../utility/localStorageControl';
import Notification from '@/components/utilities/auth-info/Notification';
import { getUnSeenNotifications, notificationViewed } from '@/services/notifications';
const { Header, Sider, Content } = Layout;

export default {
  name: 'WithAdminLayout',

  mounted() {
    this.getNotifications()
    this.initInterval()
    window.addEventListener('resize', this.getDimensions);
  },
  unmounted() {
    clearInterval(this.interval)
    window.removeEventListener('resize', this.getDimensions);

  },
  components: {
    Div,
    Header,
    Layout,
    Sider,
    Content,
    SmallScreenAuthInfo,
    AuthInfo,
    AsideItems,
    PerfectScrollbar,
    Notification
  },
  data(){
    return {
      openNotiBell: ref(false),
      notifications: [],
      interval: '',
      width: document.documentElement.clientWidth,
      //height: document.documentElement.clientHeight
    }
  },
  methods: {
    toggleNotiBell(){
      this.openNotiBell = !this.openNotiBell
    },
     async getNotifications () {
      if(getItem("token") != undefined){
        const response = await getUnSeenNotifications()
        if (response.success) {
          this.notifications= response.notifications?.data || []
        }else{
          this.notifications= []
        }
      }
    },
    initInterval(){
      this.interval = setInterval(this.getNotifications, 60000)
    },
    async deleteNotification(id) {

      this.notifications.some((notification, index) => {
        if (notification.id === id) {
          this.notifications.splice(index, 1);
          return true;
        }
        return false;
      });
      await notificationViewed(id);

    },
    getDimensions(){
       //console.log(document.documentElement.clientWidth)
      this.width = document.documentElement.clientWidth
       if( this.width >= 1226){
         this.collapsed = false
       }else{
         this.collapsed = true
       }
    },
  },
  setup() {
    const collapsed = ref(false);
    const hide = ref(true);
    const searchHide = ref(true);
    const customizerAction = ref(false);
    const activeSearch = ref(false);
    const user = getItem('userData') ? getItem('userData') : null;

    const getRolesUser = () => {
      let roles = "";
      const rolesUser = getItem("roles");
      if(rolesUser.length){
        rolesUser.forEach((element, index) => {
          if(element.permiso == "activo"){
            if(parseInt(index + 1) != rolesUser.length){
              roles += element.nombre + ", ";
            } else {
              roles += element.nombre;
            }
          }
        })
      }
      return roles;
    }

    var dataDOM = reactive({
      userName: user != null ? user.nombre + ' ' + user.apellido_paterno + ' ' + user.apellido_materno : "",
      rolesUser: user != null ? getRolesUser() : ""
    });


    const { dispatch, state } = useStore();

    const rtl = computed(() => state.themeLayout.rtlData);
    const darkMode = computed(() => state.themeLayout.data);
    const topMenu = computed(() => state.themeLayout.topMenu);
    const titleHeader = computed(() => state.themeLayout.titleHeader);

    collapsed.value = window.innerWidth <= 1226 && true;

    const toggleCollapsed = e => {
      if(e){
        e.preventDefault();
      }
      collapsed.value = !collapsed.value;
    };

    const onShowHide = h => {
      hide.value = !h;
      searchHide.value = true;
    };
    const toggleSearch = () => {
      activeSearch.value = !activeSearch.value;
    };

    const toggleCollapsedMobile = (e) => {
      if (innerWidth <= 1200) {
        e.preventDefault();
        collapsed.value = !collapsed.value;
      }
    };

    const onRtlChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'rtl');
      dispatch('changeRtlMode', true);
    };

    const onLtrChange = () => {
      const html = document.querySelector('html');
      html.setAttribute('dir', 'ltr');
      dispatch('changeRtlMode', false);
    };

    const modeChangeDark = () => {
      dispatch('changeLayoutMode', true);
    };

    const modeChangeLight = () => {
      dispatch('changeLayoutMode', false);
    };

    const modeChangeTopNav = () => {
      dispatch('changeMenuMode', true);
    };

    const modeChangeSideNav = () => {
      dispatch('changeMenuMode', false);
    };


    const onEventChange = {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    };
    return {
      toggleCollapsed,
      toggleCollapsedMobile,
      onShowHide,
      collapsed,
      hide,
      searchHide,
      toggleSearch,
      customizerAction,
      activeSearch,
      innerWidth: window.innerWidth,
      rtl,
      darkMode,
      topMenu,
      onEventChange,
      dataDOM,
      titleHeader,

    };
  },
};
</script>
<style>

@media (min-width: 1226px) {
  .btn-collapsed {
    display: none;
  }
}
.header--notification-icon{
  width: 30px;
  color: #FFFFFF;
  display: flex;
  align-items: center;

}
.vertical-line{
  display: inline-block;
  border-left: 1px solid #FFFFFF;
  margin: 0 10px;
  height: 24px;
}
.ps {
  height: calc(100vh - 100px);
}
.break-text{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
