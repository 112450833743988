import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";


const getUnSeenNotifications = async () => {
  const route = "notifications";
  const token = getItem("token");
  const request = {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}
const notificationViewed = async (notificationId) => {
  const route = `notifications/notify-viewed/${notificationId}`;
  const token = getItem("token");
  const request = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}


const getNotificationsHistory = async (search, filter, page) => {
  const route = `notifications/index?page=${page}&search=${search}&date=${filter}`;
  const token = getItem("token");
  const request = {
    method: "GET",

    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}
const configTrackingNotificationToResponsible = async (eventID, data) => {
  const route = `events/config/${eventID}`;
  const token = getItem("token");
  const request = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
    body: data
  };
  return await sendRequest(route, request);
}


export{
  getUnSeenNotifications,
  notificationViewed,
  getNotificationsHistory,
  configTrackingNotificationToResponsible
}
