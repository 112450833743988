<template>
  <div class="notification">
    <a-popover placement="bottomLeft" trigger="click"
               v-model:visible="visible">
      <template #content >
        <AtbdTopDropdwon class="atbd-top-dropdwon notifications-content" >
          <sdHeading class="notification__header">
            <span class="title-text">Notificaciones</span>
          </sdHeading>
          <div  class='line-horizontal'></div>
          <perfect-scrollbar
            class='notifications-list'
            :options="{
              wheelSpeed: 1,
              swipeEasing: false,
              suppressScrollX: false,
            }"
          >
            <ul  class="notification-list" v-if="notifications.length>0">
              <NotificationItem
                v-for='notification in notifications' :key='notification.id'
                :notification='notification'
                :deleteNotification = 'deleteNotification'
                @close-noty='hide'
              />
            </ul>
            <div v-else class="no-data-msg">
                No hay notificaciones que mostrar
            </div>

          </perfect-scrollbar>
          <div v-if='notifications.length>0' class='line-horizontal'/>
            <span class="btn-seeAll" @click='handleSeeAllNotiClick' v-if='notifications.length>0'>
              Ver todas las notificaciones
            </span>


        </AtbdTopDropdwon>
      </template>
      <a-badge :dot='notifications.length>0' :offset="[-8, -5]">
          <img src='@/static/img_events/SEJ_Eventos_Navbar_Notificaciones.svg' alt='bell-icon' width='20'/>
      </a-badge>
    </a-popover>
  </div>
</template>
<script>
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import { AtbdTopDropdwon } from './auth-info-style';
import NotificationItem from './NotificationItem.vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';
export default {
  name: 'Notification',
  components: {
    AtbdTopDropdwon,
    PerfectScrollbar,
    NotificationItem
  },

  props:{
    //visible: VueTypes.bool,
    notifications: Array,
    deleteNotification: Function
  },

  setup() {
    const router = useRouter();
    const visible = ref(false);

    const hide = () => {
      visible.value = false;
    };
    const handleSeeAllNotiClick = ()=>{
      router.push({ path:'/notifications' })

      //this.closeNoty()
      hide();
    };
    return {
      visible,
      hide,
      handleSeeAllNotiClick
    };
  },
};
</script>
<style scoped lang='sass'>

.notification
  padding: 0
.notifications-content
  padding: 0
  width: 375px
  min-width: 320px

  @media screen and (max-width: 991px)
    width: 100%

  .notification__header
    padding: 0 18px
    .title-text
      font-family: 'Nutmeg Bold'
      font-size: 18px
      text-align: left
  .notifications-list
    padding: 0 18px
    height: auto
    max-height: calc(80vh - 64px)

.ps
  max-height: 713px
  height: 200px
.line-horizontal
  border-top: 1px solid #CCCCCC
  margin-left: -15px
  margin-right: -18px
.no-data-msg
  margin-top: 10px
  width: 100%
  text-align: center
  font-family: 'Avenir Book'

.btn-seeAll
    margin-top: 8px
    display: flex
    width: 100%
    font-size: 13px
    font-weight: 500
    color: #5f63f2
    padding: 15px 0
    justify-content: center
    text-align: center

    &:hover
      cursor: pointer
      background-color: #F1F3F4


</style>
