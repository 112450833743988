<template>
  <InfoWraper>
    <div class="nav-author">
      <sdPopover placement="bottomRight" action="click">
        <template v-slot:content>
          <UserDropDwon>
            <div class="user-dropdwon">
              <span v-on:click="openModal" class="user-dropdwon__bottomAction title-normal type-button"> Ver rol </span>
              <span v-on:click="SignOut" class="user-dropdwon__bottomAction title-normal type-button"> Cerrar sesión </span>
            </div>
          </UserDropDwon>
        </template>
        <a to="#" class="head-example">
          <a-avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
        </a>
      </sdPopover>
    </div>
  </InfoWraper>
  <!--START::MODAL ROLES -->
  <sdModal
    type="primary"
    :visible="showModal"
    :onCancel="handleCancel"
    class="title-bold-normal"
  >
    <a-row :gutter="25" class="mt-1">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24">
        <h3 class="title-normal">
          Roles
        </h3>
      </a-col>
    </a-row>
    <a-row :gutter="25">
      <a-col :xxl="24" :lg="24" :md="24" :xs="24">
        <span>{{  roles }}</span>
      </a-col>
    </a-row>
  </sdModal>
  <!--END::MODAL ROLES -->
</template>

<script>
import { InfoWraper, UserDropDwon } from './auth-info-style';
import { removeItem, getItem } from '../../../utility/localStorageControl';
import { signOutSsid } from '../../../services/auth';

export default {
  name: 'AuthInfo',
  components: {
    InfoWraper,
    UserDropDwon,
  },
  data() {
    return {
      flag: 'english',
      showModal: false,
      roles: ""
    };
  },
  async setup() {

    const SignOut = async () => {
      await signOutSsid().then(() => {}).catch(() => {})
      removeItem("ssid");
      removeItem("userData");
      removeItem('roles');
      removeItem('token');
      location.replace("https://mi.sej.jalisco.gob.mx");
    };

    return {
      SignOut,
    };
  },
  methods: {
    onFlagChangeHandle: function(value) {
      this.flag = value;
    },
    handleCancel: function() {
      this.showModal = false;
    },
    openModal: function() {
      this.getRolesByString();
      this.showModal = true;
    },
    getRolesByString: function() {
      this.roles = "";
      getItem("roles").map((element, index) => {
        if(element.permiso == "activo"){
          const coma = (index == getItem("roles").length -1 ? "" : ", ")
          this.roles += element.nombre + coma;
        }
      })
    }
  },
};
</script>
<style scoped>
.type-button:hover{
  cursor: pointer;
}
</style>
