<template>
  <a-row style="width: 100% !important;height: 4rem; ">
    <a-col
      :lg="24"
      :md="24"
      :sm="24"
      :xs="24"
      class="align-center-v navbar-brand ml-3"
      style="border-bottom: 1px solid #dfdada;"
    >
      <a
        :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'"
        href="https://mi.sej.jalisco.gob.mx/"
        target="_blank"
      >
        <!--<img
          :src="!darkMode ? require(`../static/img_events/SEJ enventos_logo recrea.svg`) : require(`../static/img_events/SEJ enventos_logo recrea.svg`)"
          alt="logo"
        />-->
        <a-row>
          <a-col
            :xxl="12" 
            :lg="12" 
            :md="12"
            :xs="12"
          >
            <div style="width: 2rem;height: 2rem;background: #FEC62E">
            </div>
          </a-col>
          <a-col
            :xxl="12" 
            :lg="12" 
            :md="12"
            :xs="12"
          >
            <h1 class="title-normal" style="font-size: 20px;">
              Eventos
            </h1>
          </a-col>  
        </a-row>
      </a>
    </a-col>
  </a-row>
  <a-menu
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    :mode="mode"
    :theme="darkMode ? 'dark' : 'light'"
  >
    <router-link to="/home/events/"  key="home"  @click="toggleCollapsed">
      <a-menu-item
        v-if="roles.DIRECTOR_DE_CCT || 
          roles.PARTICIPANTE || 
          roles.ADMINISTRADOR_GENERAL || 
          roles.ADMINISTRADOR_DE_AREA ||
          roles.RESPONSABLE_DE_REGISTRO ||
          roles.RESPONSABLE_DE_EVENTO && state.user != undefined"
        >
          <sdFeatherIcons type="home" />
          <span class="title-normal ml-3">
            Inicio
          </span>
      </a-menu-item>
    </router-link>

    <router-link to="/my-events/index" key="myEvents"  @click="toggleCollapsed">
      <a-menu-item
        v-if="roles.DIRECTOR_DE_CCT ||
        roles.PARTICIPANTE ||
        roles.ADMINISTRADOR_GENERAL || 
        roles.ADMINISTRADOR_DE_AREA ||
        roles.RESPONSABLE_DE_REGISTRO ||
        roles.RESPONSABLE_DE_EVENTO &&
        state.user != undefined"
        >
          <sdFeatherIcons type="calendar" />
          <span class="title-normal ml-3">
            Mis eventos
          </span>
      </a-menu-item>
    </router-link>

    <router-link to="/events/create"  key="createEvent"  @click="toggleCollapsed" >
      <a-menu-item 
        v-if="roles.ADMINISTRADOR_GENERAL ||
        roles.ADMINISTRADOR_DE_AREA ||
        roles.RESPONSABLE_DE_EVENTO &&
        state.user != undefined"
        >
          <sdFeatherIcons type="grid" />
          <span class="title-normal ml-3">
            Crear un evento
          </span>
      </a-menu-item>
    </router-link>

    <router-link to="/validation/index" key="validation"  @click="toggleCollapsed">
      <a-menu-item 
        v-if="roles.ADMINISTRADOR_GENERAL || roles.ADMINISTRADOR_DE_AREA && state.user != undefined"
        >
          <sdFeatherIcons type="check-circle" />
          <span class="title-normal ml-3">
            Validación de eventos
          </span>
      </a-menu-item>
    </router-link>

    <router-link to="/notifications"  @click="toggleCollapsed">
      <a-menu-item
        v-if="roles.PARTICIPANTE ||
        roles.DIRECTOR_DE_CCT || 
        roles.ADMINISTRADOR_GENERAL || 
        roles.ADMINISTRADOR_DE_AREA || 
        roles.RESPONSABLE_DE_EVENTO ||
        roles.RESPONSABLE_DE_REGISTRO
        &&
        state.user != undefined"
        key="notifications"
        >
          <sdFeatherIcons type="bell" />
          <span class="title-normal ml-3">
            Notificaciones
          </span>
      </a-menu-item>
    </router-link>



  </a-menu>
  <a-row style="width: 100% !important;height: 9rem; position: absolute;bottom:0;">
    <a-col
      :lg="24"
      :md="24"
      :sm="24"
      :xs="24"
      class="align-center-v navbar-brand"
      style="align-items: center;justify-content: center;"
    >
      <router-link
        :class="topMenu && innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'"
        to="/"
      >
        <img
          :src="!darkMode ? require(`../static/img_events/SEJ enventos_logo jalisco.svg`) : require(`../static/img_events/SEJ enventos_logo jalisco.svg`)"
          alt="logo"
        />
      </router-link>
    </a-col>
  </a-row>
</template>
<script>
import { computed, reactive, ref, toRefs, watch, watchEffect } from 'vue';
import VueTypes from 'vue-types';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import versions from '../demoData/changelog.json';
import { getItem } from '../utility/localStorageControl';

export default {
  name: 'AsideItems',
  props: {
    toggleCollapsed: VueTypes.func,
    events: VueTypes.object,
    hideImage: VueTypes.bool,
  },
  setup(props) {
    const store = useStore();
    const darkMode = computed(() => store.state.themeLayout.data);
    const mode = ref('inline');
    const { events } = toRefs(props);
    const {
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
    } = events.value;
    const userData = getItem('userData');

    const router = computed(() => useRoute());

     const getRolesPermition = () => {
      const roles = getItem('roles');
      let rolesPermitions = {
        DIRECTOR_DE_CCT: false,
        ADMINISTRADOR_GENERAL: false,
        ADMINISTRADOR_DE_AREA: false,
        PARTICIPANTE: false,
        RESPONSABLE_DE_EVENTO: false,
        RESPONSABLE_DE_REGISTRO: false,
      }
      if(roles != undefined){
        roles.forEach(rol => {
          if(rol.permiso == "activo"){
            switch(rol.nombre){
              case "Director de CCT":
                rolesPermitions.DIRECTOR_DE_CCT = true;
              break;
              case "Administrador general":
              case "Administrador General":
                rolesPermitions.ADMINISTRADOR_GENERAL = true;
              break;
              case "Administrador de Área":
              case "Administrador de Area":
              case "Administrador de area":
                rolesPermitions.ADMINISTRADOR_DE_AREA = true;
              break;
              case "Participante":
                rolesPermitions.PARTICIPANTE = true;
              break;
              case "Responsable de evento":
              case "Responsable de Evento":
                rolesPermitions.RESPONSABLE_DE_EVENTO = true;
              break;
              case "Responsable de Registro":
              case "Responsable de registro":
                rolesPermitions.RESPONSABLE_DE_REGISTRO = true;
              break;
              default:
              break; 
            }
          }
        });
      }
      return rolesPermitions;
    }

    const state = reactive({
      selectedKeys: ['home'],
      openKeys: ['dashboard'],
      preOpenKeys: ['dashboard'],
      user: userData,
      roles: getRolesPermition()
    });

    watchEffect(() => {
      if (router.value.matched.length) {
        if (router.value.matched.length > 2) {
          state.selectedKeys = [router.value.matched[2].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else if (router.value.matched.length > 3) {
          state.selectedKeys = [router.value.matched[3].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        } else {
          state.selectedKeys = [router.value.matched[1].name];
          state.openKeys = [router.value.matched[1].name];
          state.preOpenKeys = [router.value.matched[1].name];
        }
      }
    });

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      },
    );

    return {
      mode,
      ...toRefs(state),
      darkMode,
      onRtlChange,
      onLtrChange,
      modeChangeDark,
      modeChangeLight,
      modeChangeTopNav,
      modeChangeSideNav,
      versions,
      state,
    };
  },
};
</script>

<style scoped lang='sass'>
.logo
  font-family: 'Nutmeg Regular'
  display: flex
  align-items: center
  color: #404040
  letter-spacing: 0.72px
  font-size: 21px
  gap: 8px
  margin-left: 23px
  .square
    width: 48px
    height: 48px
    background-color: #FEC62E
    border-radius: 7px
    display: block

.title-normal
  font-family: "Avenir Heavy"

</style>
