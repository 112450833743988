<template>
  <sdModal
    title="Configuración de notificaciones por evento"
    type="primary"
    :onOk="handleSubmit"
    :visible="visible"
    :onCancel="handleCancelModal"
    class="title-bold-normal">
    <div>
      <a-row>
        <a-col
          :xxl="24"
          :lg="24"
          :md="24"
          :xs="24"
        >
          <FormValidationWrap>
            <VerticalFormStyleWrap>
              <a-form
                name="sDash_validation-form"
                ref="formRef"
                :model="modalForm"
                layout="vertical"
                class='body'
              >
                <a-form-item ref="email_notifications" name="email_notifications" class="form-font pt-0 mx-3">
                  <a-checkbox v-model:checked="modalForm.email_notifications">Habilitar notificaciones por correo</a-checkbox>
                </a-form-item>
                <a-form-item ref="web_notifications" name="web_notifications" class="form-font pt-0 mx-3">
                  <a-checkbox v-model:checked="modalForm.web_notifications">Habilitar notificaciones en sistema</a-checkbox>
                </a-form-item>
              </a-form>
            </VerticalFormStyleWrap>
          </FormValidationWrap>
        </a-col>
        <a-col
          style="text-align: center;"
          :xxl="24"
          :lg="24"
          :md="24"
          :xs="24"
          v-if='!loading'>
          <button class="button-normal-outlet mr-2" v-on:click="handleCancelModal">
            <span class="title-normal">Cancelar</span>
          </button>
          <button :disabled='!modalForm.email_notifications && !modalForm.web_notifications' class="button-normal" @click="handleSubmit">
            <span class="title-normal">Aceptar</span>
          </button>
        </a-col>
        <a-col
          style="text-align: center;"
          :xxl="24"
          :lg="24"
          :md="24"
          :xs="24"
          v-else>
          <button class="button-normal-outlet mr-2" v-on:click="handleCancelModal">
            <span class="title-normal">Cancelar</span>
          </button>
          <button :disabled='!modalForm.email_notifications && !modalForm.web_notifications' class="button-normal" @click="handleSubmit">
            <span class="title-normal">Aceptar</span>
          </button>
        </a-col>
      </a-row>
    </div>
  </sdModal>
</template>
<script>
  import VueTypes from 'vue-types';
  import { configTrackingNotificationToResponsible } from '@/services/notifications';
  import { Notification } from 'ant-design-vue';

  export default {
    name: 'NotificationConfigModal',
    props: {
      visible: VueTypes.bool,
      eventID: VueTypes.string
    },
    data() {
      return {
        loading: false,
        modalForm: {
          email_notifications: false,
          web_notifications: false,
        }
      }
    },
    methods:{
      async handleSubmit(){
          this.loading =  true;
          const data = JSON.stringify(this.modalForm)
          const response = await configTrackingNotificationToResponsible(this.eventID, data)
          if(response.success){
            Notification['success']({ message: 'Éxito', description:response.message, });
            this.handleCancelModal()
          }else{
            Notification['error']({ message: 'Error', description: 'Oops ocurrió un error inesperado' });

          }
          this.loading =  false;
      },
      handleCancelModal(){
        this.$emit("cancel-modal")
      }
    }
  }
</script>

<style scoped lang='sass'>
.body
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
.button-normal
  background: #3FA7E1
  color: #fff
  border-radius: 20px
  border: none
  padding: 8px 15px 8px 15px
  cursor: pointer
  &:disabled
    background: rgba(63, 167, 225, 0.24)
    cursor: default
.button-normal-outlet
  background: none
  color: #636668
  border-radius: 20px
  border: 1px solid #636668
  padding: 8px 15px 8px 15px
  cursor: pointer
.form-font
  font-family: 'Nutmeg Regular'

.card
  box-shadow: 0px 3px 6px #00000029
  border-radius: 20px
</style>
