<template>

    <li @click='handleClickNotification' class='notifications'>
        <div class="atbd-top-dropdwon__content ">
          <div v-if='notification.type===1' class="notification-icon">
            <img  src='@/static/img_events/SEJ_Eventos_norificacion_generica.svg' alt='noti-icon' width='20' height='24'/>
          </div>
          <div v-else-if='notification.type===2' class="notification-icon icon-2">
            <img  src='@/static/img_events/Eventos_notificacion_aprobado.svg' alt='noti-icon' width='20' height='24'/>
          </div>
          <div v-else-if='notification.type===3' class="notification-icon icon-3">
            <img  src='@/static/img_events/Eventos_notificacion_rechazo.svg' alt='noti-icon' width='20' height='24'/>
          </div>
          <div v-else class="notification-icon">
            <img src='@/static/img_events/SEJ_Eventos_norificacion_generica.svg' alt='noti-icon' width='20' height='24'/>
          </div>

          <div class="notification-content d-flex">
            <div class="notification-text">
              <p class='notification__title'>{{notification.description}} </p>
              <p class='notification__description'>{{timeAgo (notification.created_at)}}</p>
            </div>

          </div>
        </div>
    </li>
    <notifications-config-modal
      :visible='visibleModal'
      :event-i-d='notification.url'
      @cancel-modal='handleCancelModal'
    />
</template>

<script>
import { timeAgo } from '@/utility/utility.js'
import { useRouter } from 'vue-router';
import NotificationsConfigModal from '@/components/notifications-config-modal/NotificationsConfigModal';
export default {
  name: 'NotificationItem',
  components: {
    NotificationsConfigModal
  },
  props:{
    notification: Object,
    deleteNotification: Function,
  },
  data(){
    return {
      visibleModal: false
    }
  },
  methods:{
    handleCancelModal(){
      this.visible = false
      this.deleteNotification(this.notification.id);
    },
    handleClickNotification(){
      if(this.notification.type == 4){
        this.visibleModal = true
      }else {
        if (this.notification.url) {
          if (this.notification.url.includes('/app')) {
            const urlWithoutAppPrefix = this.notification.url.slice(4)
            this.router.push(urlWithoutAppPrefix);
          } else if (this.notification.url.includes('app/')) {
            const urlWithoutAppPrefix = this.notification.url.slice(3)
            this.router.push(urlWithoutAppPrefix);
          } else {
            this.router.push(this.notification.url);
          }
          this.deleteNotification(this.notification.id);
        }
      }
      this.$emit('close-noty');

    },

  },
  setup(){
    const router = useRouter();
    return{
      timeAgo,
      router
    }
  }
};

</script>

<style lang='sass' scoped>
.notifications
  margin-top: 18px
  &:hover
    cursor: pointer
    background-color: #F1F3F4

  .notification-icon
    background: rgba(254, 198, 46, 0.2) 50% 50%
    color: #FEC62E
    &.icon-2
      background: rgba(232, 57, 77, 0.2) 50% 50%
      color: #E8394D
    &.icon-3
      background: rgba(232, 57, 77, 0.2) 50% 50%
      color: #51AF2C
  .notification-content
    .notification-text
      .notification__title
        text-align: left
        font-family: 'Avenir Roman'
        color: #606469
        font-size: 18px

      .notification__description
        text-align: left
        font-family: 'Avenir Book'
        color: #989898
        font-size: 16px

.pointer
  cursor: pointer

</style>
